import React, {ChangeEvent, Dispatch, FC, SetStateAction, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useRouter} from "next/router";
import Link from "next/link";
import {setPopup} from "../../../../../store/general";
import {apiUrlWithStore} from "../../../../../helper";
import {getCartDataMount} from "../../../../../store/cart";
import {FailSvg} from "../../../../../svg";
import {useToast} from "../../../../../atoms/Toast/useToast";
import {IState} from "../../../../../types/state";
import cls from "../customHeader.module.scss"
import {setAuth, setCompanyName, setCustomerGroupID, setID, setToken} from "../../../../../store/customer";
import {TranslationsType} from "../../../../../types/homePageTypes";
import {ICurrencies} from "../../MainLayout";
import CustomSocialLogin from "./CustomSocialLogin";
import CustomTextField from "../../../../../atoms/CWV/CustomTextField";

interface IProps {
    setOpen: Dispatch<SetStateAction<boolean>>;
    translationsData: TranslationsType;
    selectedRate: ICurrencies;
    selectedLocale: string;
    isSocialLinksActive: Array<Record<string, string>>;
    dbName: string
}

const IndicatorDropDownBody: FC<IProps> = ({
                                               setOpen,
                                               translationsData,
                                               selectedRate,
                                               selectedLocale,
                                               isSocialLinksActive,
                                               dbName
                                           }) => {
    const {push: routerPush} = useRouter();
    const dispatch = useDispatch();
    const {open: toastOpen} = useToast();
    const cartToken = useSelector((state: IState) => state.cartToken.cartToken);
    const [{email, password}, setForm] = useState({
        email: "",
        password: "",
    })

    const handleForm = ({target: {name, value}}: ChangeEvent<HTMLInputElement>) => {
        setForm((state) => {
            return {
                ...state,
                [name]: value
            }
        })
    }

    const fetchToLogin = async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        try {
            const response = await fetch(apiUrlWithStore("/api/customer/login?token=true"), {
                method: "POST",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({email, password}),
            });
            const {token, data: {group: {id: groupId = ""} = {}, id: dataId = "", companyname: companyName = ''} = {}} = await response.json();
            const handleTokenValue = {
                "true": async () => {
                    dispatch(setCustomerGroupID(groupId))
                    dispatch(setAuth(true));
                    dispatch(setToken(token));
                    dispatch(setCompanyName(companyName));
                    dispatch(setID(dataId));
                    dispatch(setPopup(false));
                    await routerPush("/account/profile/");
                    submitCartData(token);
                },
                "false": () => {
                    toastOpen(
                        <span className={`d-flex chek-fms`}>
                            <FailSvg/>
                            {translationsData.InvalidEmailOrPassword}
                        </span>
                    );
                }
            }
            handleTokenValue[`${!!token}`]()
        } catch (err) {
            console.error(err);
        }
    };

    const submitCartData = (token: string) => {
        dispatch(getCartDataMount(cartToken, {token}, selectedLocale, selectedRate?.code))
    };

    return (
        <div className={`${cls["account-menu"]}`}>
            <form className={`${cls["account-menu__form"]}`}>
                <div className={`${cls["account-menu__form-title"]}`}>
                    {translationsData?.SignIn}
                </div>
                <div className={`${cls["account-menu_loginAndPass"]}`}>
                    <CustomTextField
                        className={`${cls.custom_input_field} `}
                        id="indicator_email"
                        type="email"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={handleForm}
                        placeholder={translationsData?.Email}
                    />
                </div>
                <div className={`${cls["account-menu_loginAndPass"]}`}>
                    <CustomTextField
                        className={`${cls.custom_input_field} `}
                        id="indicator_password"
                        type="password"
                        name="password"
                        autoComplete="off"
                        value={password}
                        onChange={handleForm}
                        placeholder={translationsData?.Password}
                    />
                    <Link
                        href="/forgot-password"
                        className={`${cls["account-menu__form-forgot-link"]}`}
                    >
                        <a onClick={() => {
                            dispatch(setPopup(false));
                            setOpen(prevState => !prevState)
                        }}>
                            {translationsData?.ForgotPassword}
                        </a>
                    </Link>
                </div>
                <div className={`form-group ${cls["account-menu__form-button"]}`}>
                    <button
                        onClick={fetchToLogin}
                        type="submit"
                        className={`btn btn-orange login-drop-btn ${cls["rounded-pills"]}`}
                    >
                        {translationsData?.SignIn}
                    </button>
                </div>
                <CustomSocialLogin translationsData={translationsData} isSocialLinksActive={isSocialLinksActive}
                                   dbName={dbName}/>
                <div className={`${cls["account-menu__form-link"]}`}>
                    <span className={`${cls["new-customer"]}`}>
                        {translationsData?.NewCustomer}
                    </span>
                    <Link href="/signup">
                        <a onClick={() => {
                            dispatch(setPopup(false));
                            setOpen(prevState => !prevState)
                        }}>
                            {translationsData?.SignUp}
                        </a>
                    </Link>
                </div>
            </form>
        </div>
    )
}

export default IndicatorDropDownBody;