import React, {FC, Fragment, memo, ReactNode} from "react";
import Link from "next/link";
import cls from "../customHeader.module.scss"

interface IIndicatorButton {
    url: string;
    handleButtonClick: (event: { preventDefault: () => void }) => void;
    title: ReactNode;
    titleSpan: string;
}

const IndicatorButton: FC<IIndicatorButton> = (
    {
        url,
        handleButtonClick,
        title,
        titleSpan
    }
) => {
    const handleElementsWithUrl = {
        "true": (
            <Link href={url} onClick={handleButtonClick} prefetch={true} className={cls.indicator__button} legacyBehavior={true}>
                <a
                    aria-label={url}
                    className={cls.indicator__button}
                >
                    {title}
                    {/*<span*/}
                    {/*    onClick={handleButtonClick}*/}
                    {/*    className={`${cls["indicator-title-fms"]}`}*/}
                    {/*>*/}
                    {/*    {titleSpan}*/}
                    {/*</span>*/}
                </a>
            </Link>
        ),
        "false": (
            <button
                aria-label="indicatorButton"
                className={cls.indicator__button}
                onClick={handleButtonClick}
            >
                {title}
                {/*<span*/}
                {/*    onClick={handleButtonClick}*/}
                {/*    className={`${cls["indicator-title-fms"]}`}*/}
                {/*>*/}
                {/*    {titleSpan}*/}
                {/*</span>*/}
            </button>
        )
    }

    return (
        <Fragment>
            {handleElementsWithUrl[`${!!url}`]}
        </Fragment>
    )
};

export default IndicatorButton;